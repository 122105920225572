import styled, { css } from 'styled-components';

interface WhitespaceProps {
  horizontal?: number;
  vertical?: number;
}

const Whitespace = styled.span`
  ${({ horizontal = 0, vertical = 0 }: WhitespaceProps) => css`
    display: inline-block;
    width: ${horizontal}px;
    height: ${vertical}px;
  `}
`;

export default Whitespace;
