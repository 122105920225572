import styled, { css } from 'styled-components';

export const Hero = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  box-sizing: border-box;
  overflow: hidden;
  background: rgb(209, 242, 255);
  background: linear-gradient(
    90deg,
    rgba(209, 242, 255, 1) 0%,
    rgba(255, 252, 183, 1) 35%,
    rgba(255, 158, 176, 1) 100%
  );
  color: #b37882;
  color: #444444;
  font-size: 40px;
  @media (max-width: 900px) {
    font-size: 25px;
    padding: 3rem 2rem;
  }
`;

export const FunnyText = styled.span`
  text-align: center;
  transform: rotate(-5deg);
  font-size: 24px;
  color: #b37882;
`;

export const GradientHeadline = styled.h1`
  display: block;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  margin: 0;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, #fe5191 0%, #3d1e17 100%);
  background-clip: text;
  -webkit-background-clip: text;
`;

export const SlideTitle = styled(GradientHeadline)<{
  $from?: string;
  $to?: string;
  $noGradient?: boolean;
}>`
  font-size: 50px;
  @media (max-width: 900px) {
    font-size: 30px;
  }
  ${({ $from = '#fe5191', $to = '#3d1e17', $noGradient = false }) =>
    $noGradient
      ? css`
          background: #fff;
          -webkit-text-fill-color: inherit;
          padding: 0.5rem;
          border-radius: 0.5rem;
        `
      : css`
          background: linear-gradient(45deg, ${$from} 0%, ${$to} 100%);
          background-clip: text;
          -webkit-background-clip: text;
        `}
`;

export const HeroImage = styled.img`
  border-radius: 50%;
  width: 300px;
  height: 300px;
  object-fit: cover;
  border: 20px solid #ff9eb0;
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
  @media (max-width: 900px) {
    width: 250px;
    height: 250px;
    border: 10px solid #ff9eb0;
  }
`;

export const Emojis = styled.span`
  font-size: 60px;
`;

export const Bar = styled.div`
  height: 5px;
  width: 100%;
`;

export const BlackBar = styled(Bar)`
  background-color: #000000;
`;

export const RedBar = styled(Bar)`
  background-color: #dd0000;
`;

export const YellowBar = styled(Bar)`
  background-color: #ffce00;
`;

export const Slide = styled.section<{
  $color?: string;
  $container?: boolean;
  $backgroundImg?: string;
  $contentPosition?: 'top' | 'center' | 'bottom' | 'space-between';
  $shade?: boolean;
  $sun?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  color: #444444;
  font-size: 40px;
  @media (orientation: landscape) {
    height: 800px;
  }
  ${({ $contentPosition = 'top' }) => css`
    justify-content: ${$contentPosition === 'top'
      ? 'flex-start'
      : $contentPosition === 'center'
      ? 'center'
      : $contentPosition === 'bottom'
      ? 'flex-end'
      : 'space-between'};
  `}
  ${({ $color = '#ffffff', $container = false, $backgroundImg }) => css`
    background: ${$backgroundImg ? `url(${$backgroundImg})` : $color};
    background-position: center;
    background-size: cover;
    padding: ${$container ? 1 : 2}rem;
    @media (max-width: 900px) {
      font-size: 25px;
      padding: ${$container ? '1rem' : '3rem 2rem'};
    }
  `}
`;

export const RoundedSlide = styled(Slide)`
  border-radius: 2rem;
  overflow: hidden;
  margin: 0 auto;
  height: 100%;
`;
