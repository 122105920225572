import {
  BlackBar,
  Emojis,
  FunnyText,
  GradientHeadline,
  Hero,
  HeroImage,
  RedBar,
  Slide,
  SlideTitle,
  YellowBar,
} from '../components/Styled';

const BirthdayPage = () => {
  return (
    <>
      <Hero>
        <GradientHeadline>Happy Birthday, Levchen!!!</GradientHeadline>
        <FunnyText>
          🇩🇪 ... or, as they say in Germany: Herzlichen Glückwunsch zum
          Geburtstag! 🇩🇪
        </FunnyText>
        <HeroImage src="/birthday/hero.jpeg" />
        <Emojis>🥳 🎉 🍾</Emojis>
      </Hero>
      <Slide $backgroundImg="/birthday/small-1.jpeg" $contentPosition="top">
        <SlideTitle $from="#B300FF" $to="#D1F2FF" $noGradient>
          Once, you were also small like this 🐣
        </SlideTitle>
      </Slide>
      <Slide $backgroundImg="/birthday/small-2.jpeg" $contentPosition="top">
        <SlideTitle $from="#B300FF" $to="#D1F2FF" $noGradient>
          Small like that 🦔
        </SlideTitle>
      </Slide>
      <Slide $backgroundImg="/birthday/28.jpeg" $contentPosition="bottom">
        <SlideTitle $from="#B300FF" $to="#D1F2FF" $noGradient>
          Now you're 28! Time flies ...
        </SlideTitle>
      </Slide>
      <Slide $backgroundImg="/birthday/fun.jpeg" $contentPosition="top">
        <SlideTitle $from="#B300FF" $to="#D1F2FF" $noGradient>
          ... when you're having fun! 🐶
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/adventure-start.jpeg"
        $contentPosition="bottom"
      >
        <SlideTitle $from="#B300FF" $to="#D1F2FF" $noGradient>
          And go on adventures
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/latest-adventure.jpeg"
        $contentPosition="top"
      >
        <SlideTitle $from="#B300FF" $to="#D1F2FF" $noGradient>
          Our latest adventure was a success
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/first-adventure-1.jpeg"
        $contentPosition="top"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          Do you still remember our very first adventure?
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/first-adventure-2.jpeg"
        $contentPosition="top"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          To Lüneburger Heide
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/first-adventure-3.jpeg"
        $contentPosition="top"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          In April 2020. I'm sure you have some better pics! 📸
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/many-adventures.jpeg"
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          We've been on many adventures since
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/always-ready-1.jpeg"
        $contentPosition="top"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          And you are always ready
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/always-ready-2.jpeg"
        $contentPosition="top"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          Always
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/as-long-as-there-is-good-food.jpeg"
        $contentPosition="center"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          As long as there is good food 😋
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/so-you-dont-have-to-cook.jpeg"
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          So you don't have to cook 👩🏼‍🍳
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg={`/birthday/vejle-1.jpeg`}
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          Wuhuu!
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg={`/birthday/vejle-2.jpeg`}
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          Colorful!
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg={`/birthday/vejle-3.jpeg`}
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          Oh, so colorful 🎨
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg={`/birthday/cheers.jpeg`}
        $contentPosition="space-between"
      >
        <FunnyText>
          <strong style={{ fontSize: '50px', color: 'gold' }}>Proooost</strong>
        </FunnyText>
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          Skååål! 🍻
        </SlideTitle>
      </Slide>
      <Slide $backgroundImg={`/birthday/bugvi.jpeg`} $contentPosition="bottom">
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          Children love you 🥰
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg={`/birthday/mons-klint-1.jpeg`}
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          You might be thinking ...
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg={`/birthday/where-is-my-gift.jpeg`}
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          "Where's my f**king gift??? It better be a nice gold necklace"
          💎&nbsp;💅🏻
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg={`/birthday/mons-klint-2.jpeg`}
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          Kidding!
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg={`/birthday/keep-going.jpeg`}
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          We have an endless amount of pics of you wearing clothes. Now let's
          get to something more interesting and see what we're doing at bedtime
          💋&nbsp;😏
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/bedtime-stories-1.jpeg"
        $contentPosition="top"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          Because now it's time ...
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/bedtime-stories-2.jpeg"
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          ... to make our bedtime stories ...
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/reality-1.jpeg"
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          ... into reality!
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/van-summer-3.jpeg"
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          Again
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/van-summer-4.jpeg"
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          We are already super experienced ⚡️
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/van-summer-1.jpeg"
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          We are prepared
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/van-summer-2.jpeg"
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          Levke approves
        </SlideTitle>
      </Slide>
      <Slide $backgroundImg="/birthday/reality-2.jpeg" $contentPosition="top">
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          I'm inviting you on a weekend trip{' '}
          <span style={{ color: 'gold' }}>
            <a
              href="https://www.instagram.com/explore/tags/vanlife/"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              #vanlife
            </a>
          </span>
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/reality-3.jpeg"
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          You can go to{' '}
          <a style={{ color: '#FF9EB0' }} href="https://paulcamper.de/">
            Paul&nbsp;Camper
          </a>{' '}
          and pick a van you like. Then I will plan the rest. We will go for an
          extended weekend (Fri-Sun) van adventure.
        </SlideTitle>
      </Slide>
      <Slide $backgroundImg="/birthday/ready.jpeg" $contentPosition="bottom">
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          I hope you are ready for our next adventure
        </SlideTitle>
      </Slide>
      <Slide $backgroundImg="/birthday/cutie-1.jpeg" $contentPosition="bottom">
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          Cutie
        </SlideTitle>
      </Slide>
      <Slide $backgroundImg="/birthday/cutie-2.jpeg" $contentPosition="bottom">
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          Also cutie
        </SlideTitle>
      </Slide>
      <Slide
        $backgroundImg="/birthday/happy-birthday.jpeg"
        $contentPosition="bottom"
      >
        <SlideTitle $from="#FFFFFF" $to="#FFFCB7" $noGradient>
          Happy birthday 🍷
        </SlideTitle>
      </Slide>
      <Slide $backgroundImg="/birthday/the-end.jpeg" $contentPosition="bottom">
        <SlideTitle $noGradient>
          <SlideTitle $from="#888888" $to="#B30704">
            That's all for now! Have an amazing day, Honig. I'm looking forward
            to going on many more adventures with you ❤️
          </SlideTitle>
        </SlideTitle>
      </Slide>
      <BlackBar />
      <RedBar />
      <YellowBar />
    </>
  );
};

export default BirthdayPage;
