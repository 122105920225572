import styled from "styled-components";

const Page = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(232, 211, 209, 1);
  background: radial-gradient(
    circle,
    rgba(232, 211, 209, 1) 37%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 4rem 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  box-sizing: border-box;
`;

export default Page
