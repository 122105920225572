import styled from 'styled-components';
import Button from '../Button';
import Page from '../components/Page';
import Whitespace from '../Whitespace';

const Card = styled.section`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 1px 10px 1px;
  border-radius: 0.5rem;
  max-width: 900px;
  width: 90%;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

const CardContent = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.5rem 3rem;
  box-sizing: border-box;

  @media (max-width: 900px) {
    padding: 1.25rem 1.5rem;
  }
`;

const PersonImg = styled.img`
  border-radius: 50%;
  height: 350px;
  width: 350px;
  @media (max-width: 900px) {
    height: 250px;
    width: 250px;
  }
`;

const ImageContainer = styled(CardContent)`
  justify-content: center;
  align-items: center;
  min-height: calc(5rem + 350px);
  @media (max-width: 900px) {
    min-height: calc(5rem + 250px);
  }
`;

const ButtonContainer = styled.section`
  display: flex;
  @media (min-width: 900px) {
    ${Button} ~ ${Button} {
      margin-left: 12px;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align: center;
    ${Button} {
      width: 100% !important;
      max-width: 250px;
    }
    ${Button} ~ ${Button} {
      margin-top: 12px;
    }
  }
`;

const HomePage = () => {
  return (
    <Page>
      <Card>
        <CardContent>
          <div>
            <h1
              style={{
                fontSize: '3rem',
                margin: '0',
                letterSpacing: '0.25rem',
              }}
            >
              Levke <br /> Sydow
            </h1>
            <h2
              style={{
                fontSize: '1.4rem',
                color: '#B39E98',
                margin: '0.5rem 0 1.5rem 0',
                letterSpacing: '0.16rem',
                fontWeight: 'normal',
              }}
            >
              Communication Professional
            </h2>
            <p
              style={{
                fontSize: '0.8rem',
                lineHeight: '1.6',
                margin: '0.5rem 0 0 0',
              }}
            >
              Currently I am working as an Account Manager in an email marketing
              agency. <br /> <br /> Take a look at my portfolio and feel free to
              reach out. I'm excited to discuss new, interesting projects with
              you.
            </p>
          </div>
          <Whitespace vertical={40} />
          <ButtonContainer>
            <Button
              color="#B39E98"
              onClick={() => window.open('./portfolio.pdf')}
            >
              Portfolio
            </Button>
            <Button
              color="#B39E98"
              onClick={() => window.open('https://linkedin.com/in/levke-sydow')}
            >
              LinkedIn
            </Button>
            <Button color="#B39E98" onClick={() => window.open('./cv.pdf')}>
              CV
            </Button>
          </ButtonContainer>
        </CardContent>
        <ImageContainer>
          <PersonImg alt="levke sydow" src="./levke.png" />
        </ImageContainer>
      </Card>
    </Page>
  );
};

export default HomePage;
